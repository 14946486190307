
.RenbanConstraint.line {
    background: #cebfef;
    width: 0.2em;
    bottom: calc(50% - 0.1em) !important;
    left: calc(50% - 0.1em) !important;
    border-bottom-left-radius: 0.1em;
    border-bottom-right-radius: 0.1em;
    transform-origin: calc(50%) calc(100% - 0.1em + 0.5px);
    z-index: -1;
}

.RenbanConstraint.bg {
    outline: 1px solid white;
    z-index: -2;
}

.RenbanConstraint.d1 {
    top: -0.45em !important;
    transform: rotate(-45deg);
}

.RenbanConstraint.d2 {
    transform: rotate(0deg);
}

.RenbanConstraint.d3 {
    top: -0.45em !important;
    transform: rotate(45deg);
}

.RenbanConstraint.d4 {
    transform: rotate(-90deg);
}

.RenbanConstraint.d6 {
    transform: rotate(90deg);
}

.RenbanConstraint.d7 {
    top: -0.45em !important;
    transform: rotate(-135deg);
}

.RenbanConstraint.d8 {
    transform: rotate(180deg);
}

.RenbanConstraint.d9 {
    top: -0.45em !important;
    transform: rotate(135deg);
}