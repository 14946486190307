.LittleKillerConstraint.sum {
    font-size: 0.8em;    
}

.LittleKillerConstraint.sum.d1 {
    top: auto;
    left: auto;
}

.LittleKillerConstraint.sum.d3 {
    top: auto;
    right: auto;
}

.LittleKillerConstraint.sum.d7 {
    bottom: auto;
    left: auto;
}

.LittleKillerConstraint.sum.d9 {
    bottom: auto;
    right: auto;
}

.LittleKillerConstraint.dir {
    margin: 0.1em;
    line-height: 0.8em;
}

.LittleKillerConstraint.dir.d1 {
    bottom: auto;
    right: auto;
    transform: rotate(-45deg);
}

.LittleKillerConstraint.dir.d3 {
    bottom: auto;
    left: auto;
    transform: rotate(45deg);
}

.LittleKillerConstraint.dir.d7 {
    top: auto;
    right: auto;
    transform: rotate(-135deg);
}

.LittleKillerConstraint.dir.d9 {
    top: auto;
    left: auto;
    transform: rotate(135deg);
}