.PalindromeConstraint {
    background: lightgray;
}

.PalindromeConstraint.line {
    width: 0.4em;
    bottom: calc(50% - 0.2em) !important;
    left: calc(50% - 0.2em) !important;
    border-bottom-left-radius: 0.2em;
    border-bottom-right-radius: 0.2em;
    transform-origin: calc(50%) calc(100% - 0.2em + 0.5px);
    z-index: -1;
}

.PalindromeConstraint.d1 {
    top: -0.45em !important;
    transform: rotate(-45deg);
}

.PalindromeConstraint.d2 {
    transform: rotate(0deg);
}

.PalindromeConstraint.d3 {
    top: -0.45em !important;
    transform: rotate(45deg);
}

.PalindromeConstraint.d4 {
    transform: rotate(-90deg);
}

.PalindromeConstraint.d6 {
    transform: rotate(90deg);
}

.PalindromeConstraint.d7 {
    top: -0.45em !important;
    transform: rotate(-135deg);
}

.PalindromeConstraint.d8 {
    transform: rotate(180deg);
}

.PalindromeConstraint.d9 {
    top: -0.45em !important;
    transform: rotate(135deg);
}