.UIBody {
    padding: var(--body-padding);

    --color-0: white;
    --color-1: gold;
    --color-2: darkorange;
    --color-3: yellowgreen;
    --color-4: seagreen;
    --color-5: violet;
    --color-6: mediumpurple;
    --color-7: deepskyblue;
    --color-8: royalblue;
    --color-9: gray;

    --color-violation: tomato;
    --color-fixed: whitesmoke;
    --color-selection: #ebc17d;
    --color-selection-trans: #ebc17d7F;
    --color-outline: black;
}

.UIBody a:link {
    text-decoration: none;
    color: inherit;
}

.UIBody a:hover {
    text-decoration: underline;
    color: inherit;
}

.UIBody a:visited {
    color: inherit;
}

.UIBody button {
    background: whitesmoke;
    border: 1px solid darkslategray;
    border-radius: 2px;
    cursor: pointer;
}

.UIBody button:hover {
    background: white;
}

.UIBody .c0 {
    background-color: var(--color-0);
}

.UIBody .c1 {
    background-color: var(--color-1);
}

.UIBody .c2 {
    background-color: var(--color-2);
}

.UIBody .c3 {
    background-color: var(--color-3);
}

.UIBody .c4 {
    background-color: var(--color-4);
}

.UIBody .c5 {
    background-color: var(--color-5);
}

.UIBody .c6 {
    background-color: var(--color-6);
}

.UIBody .c7 {
    background-color: var(--color-7);
}

.UIBody .c8 {
    background-color: var(--color-8);
}

.UIBody .c9 {
    background-color: var(--color-9);
}