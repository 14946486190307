.QuadConstraint { 
    font-size: 0.36em;

    position: relative;
    left: calc(100% - 1em - 0.5px) !important;
    top: calc(100% - 1em - 0.5px) !important;
    width: 2em;
    height: 2em;

    border: 1px solid black;
    border-radius: 2em;
    background: white;
    z-index: 1;

    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-content: center;
}

.QuadConstraint .digit {
    width: 35%;
    font-size: 0.9em;
    line-height: 0.9em;
    text-align: center;
}