.RatioConstraint {
    font-size: 0.4em;
    text-align: center;
    color: white;
}

.RatioConstraint .dot {
    position: relative;
    width: 1em;
    height: 1em;
    border: 1px solid black;
    border-radius: 1em;
    background: black;
    z-index: 1;
}

.RatioConstraint .l {
    top: calc(50% - 0.5em - 1px);
    left: calc(-0.5em - 1px);
}

.RatioConstraint .t {
    top: calc(-0.5em - 1px);
    left: calc(50% - 0.5em - 1px);
}