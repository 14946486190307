.DiagConstraint.neg {
    width: 142%;
    border-top: 1px solid lightgray;
    transform: rotate(45deg);
    transform-origin: 0.5px 0px;
    z-index: -1;
}

.DiagConstraint.pos {
    width: 142%;
    border-bottom: 1px solid lightgray;
    transform: rotate(-45deg);
    transform-origin: 0.5px 100%;
    z-index: -1;
}