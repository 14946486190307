.BoxConstraint.t {
    border-top: 1px solid black;
}

.BoxConstraint.r {
    border-right: 1px solid black;
}

.BoxConstraint.b {
    border-bottom: 1px solid black;
}

.BoxConstraint.l {
    border-left: 1px solid black;
}