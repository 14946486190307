.UIInputInfo {
    display: none;
    width: min(calc(100%), var(--left-width));
}

.UIInputInfo.any {
    display: block;
}

@media (orientation: landscape) {
    .UIInputInfo.landscape {
        display: block;
    }
}

@media (orientation: portrait) {
    .UIInputInfo.portrait {
        display: block;
    }
}

@media print {
    .UIInputInfo .toggle {
        display: none;
    }
}

.UIInputInfo .titleBox {
    padding: 0.5rem;
    color: white;
    cursor: pointer;
    background: slategray;
    user-select: none;
}

.UIInputInfo .title {
    font: inherit;
    margin: inherit;
    font-size: 1.2rem;
}

.UIInputInfo .author {
    font: inherit;
    margin: inherit;
    font-size: 0.8rem;
}

.UIInputInfo .toggle {
    float: right;
    user-select: none;
}

.UIInputInfo .toggle path {
    fill: whitesmoke;
}

.UIInputInfo .toggle:hover path {
    fill: white;
}

.UIInputInfo.expanded .expand {
    display: none;
}

.UIInputInfo.collapsed .collapse {
    display: none;
}

.UIInputInfo.collapsed .rules {
    display: none;
}

.UIInputInfo.expanded .rules {
    color: darkslategray;
    border: 2px solid slategray;
    border-top: none;
    margin: 0;
    padding: 0.5rem;
    font-size: 0.8rem;
    background: white;
}

.UIInputInfo .rule {
    cursor: pointer;
    user-select: none;
    list-style: none;
    padding: 2px;
}

.UIInputInfo .rule:hover {
    text-decoration: underline;
}

.UIInputInfo.collapsed .links {
    display: none;
}

.UIInputInfo.expanded .links {
    color: darkslategray;
    border: 2px solid slategray;
    border-top: none;
    padding: 0.5rem;
    margin: 0;
    font-size: 0.8rem;
    background: white;
}

.UIInputInfo .link {
    cursor: pointer;
    user-select: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    list-style: none;
    padding: 2px;
}
