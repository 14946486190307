.UIPropertyItem {
    margin: 1px 0px 1px 4px;
}

.UIPropertyItem input, .UIPropertyItem textarea {
    float: right;
    clear: none;
    font: inherit;
    font-size: small;
    border: 1px solid slategray;
    border-radius: 2px;
}

.UIPropertyItem input {
    line-height: calc(1em + 4px);
}

.UIPropertyItem textarea {
    resize: vertical;
}

.UIPropertyItem input[type="text"], .UIPropertyItem input[type="number"], .UIPropertyItem textarea {
    padding-left: 4px;
    padding-right: 4px;
    width: 12em;
}

.UIPropertyItem input.readonly, .UIPropertyItem textarea.readonly {
    background-color: var(--color-fixed);
}

.UIPropertyItem input.invalid:focus, .UIPropertyItem textarea.invalid:focus {
    background-color: var(--color-violation);
}