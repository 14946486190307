.GridSelection {
    background: var(--color-selection);
    opacity: 0.5;
    z-index: 5;
}

.GridSelection.t {
    border-top: 0.1em solid var(--color-outline);
}

.GridSelection.r {
    border-right: 0.1em solid var(--color-outline);
}

.GridSelection.b {
    border-bottom: 0.1em solid var(--color-outline);
}

.GridSelection.l {
    border-left: 0.1em solid var(--color-outline);
}

.GridSelection.c {
    opacity: 1;
    z-index: -50;
}

.GridSelection.p {
    opacity: 0.3;
    z-index: -51;
}
