.UIPrint {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 1rem;
}

.UIPrintGrid {
    background: white;
    z-index: -100;
}