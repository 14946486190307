.UIInputMode {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 0.5rem;
}

@media print {
    .UIInputMode {
        display: none;
    }
}

.UIInputMode button {
    border: solid black 1px;
    padding: 3px;
    width: 3rem;
    height: 3rem;
    cursor: pointer;
}

.UIInputMode button:hover {
    background-color: white;
}

.UIInputMode button:hover:active {
    box-shadow: 0 0 5px -1px rgba(0,0,0,0.6);
}

.UIInputMode.digit #InputDigits {
    border: solid darkslategray 2px;
    padding: 2px;
    background-color: wheat;
}

.UIInputMode.cornerMark #InputCornerMarks {
    border: solid darkslategray 2px;
    padding: 2px;
    background-color: wheat;
}

.UIInputMode.centerMark #InputCenterMarks {
    border: solid darkslategray 2px;
    padding: 2px;
    background-color: wheat;
}

.UIInputMode.colorMark #InputColorMarks {
    border: solid darkslategray 2px;
    padding: 2px;
    background-color: wheat;
}

.UIInputMode #InputDigits {
    font-size: 1.6em;
    font-weight: bold;
}

.UIInputMode #InputCornerMarks {
    display: flex;
    justify-content: space-between;
    font-size: 0.8em;
    font-weight: normal;
}

.UIInputMode #InputCenterMarks {
    font-size: 1em;
    font-weight: normal;
}

.UIInputMode #InputColorMarks {
    position: relative;
}

.UIInputMode #InputColorMarks .c {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 3rem;
    height: 3rem;
}

.UIInputMode #InputColorMarks .cb {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.UIInputMode #InputColorMarks .c1 {
    background-color: var(--color-9);
    transform: rotate(0deg) scaleY(0.57) scale(1) rotate(45deg);
    transform-origin: 0px 0px;
}

.UIInputMode #InputColorMarks .c2 {
    background-color: var(--color-1);
    transform: rotate(120deg) scaleY(0.57) scale(1) rotate(45deg);
    transform-origin: 0px 0px;
}

.UIInputMode #InputColorMarks .c3 {
    background-color: var(--color-2);
    transform: rotate(240deg) scaleY(0.57) scale(1) rotate(45deg);
    transform-origin: 0px 0px;
}