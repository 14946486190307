.UIInputStatus {
    display: flex;
    flex-direction: column;
    min-width: 13.5rem;
    max-width: min(calc(100%), var(--left-width));
    gap: 0.5em;
}

@media print {
    .UIInputStatus {
        display: none;
    }
}

.UIInputStatus .msg {
    padding: 0.4rem;
    border: 1px solid black;
    background-color: wheat;
    user-select: none;
    font-size: smaller;
}

.UIInputStatus .msg:hover {
    background-color: white;
}

.UIInputStatus .msg:active {
    box-shadow: 0 0 5px -1px rgba(0,0,0,0.6);
}

.UIInputStatus .msg .box {
    display: inline-block;
    border: 1px solid black;
    width: 0.6em;
    height: 0.6em;
}
