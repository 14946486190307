.UIPlay {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
}

.UIPlayGrid {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 1rem;
    background: white;
}

.UIPlayInput {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 1rem;
    min-width: 13.5rem;
    width: min(var(--right-width), 40rem);
}