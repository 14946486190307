.UIMenu {
    position: absolute;
    top: 0;
    left: 0;
    background: slategray;
    overflow: hidden;
    white-space: nowrap;
    box-sizing: border-box;
    padding-left: 0.5rem;
    border-right: 2px solid whitesmoke;
}

.UIMenu.collapsed {
    width: calc(3rem + 2px);
    height: 2rem;
    transition: width 0.1s;
}

.UIMenu.expanded {
    width: 15rem;
    height: 100%;
    transition: width 0.1s;
}

@media print {
    .UIMenu {
        display: none;
    }
}