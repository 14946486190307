
.ArrowConstraint.bulb {
    width: 0.9em;
    height: 0.9em;
    top: calc(50% - 0.45em - 0.12em - 1px) !important;
    left: calc(50% - 0.45em - 0.12em) !important;
    border: 0.12em solid lightgray;
    border-radius: 1em;
    background: white;
}

.ArrowConstraint.tip {
    width: 0.4em;
    height: 0.4em;
    top: calc(50% - 0.06em + 0.5px) !important;
    left: calc(50% - 0.06em - 0.4em + 0.5px) !important;
    border-top: 0.12em solid lightgray;
    border-right: 0.12em solid lightgray;
    border-radius: 0.06em;
    transform-origin: calc(100% - 0.06em) calc(0% + 0.06em);
    z-index: -2;
}

.ArrowConstraint.line {
    width: 0.12em;
    bottom: calc(50% - 0.06em) !important;
    left: calc(50% - 0.06em) !important;
    border-bottom-left-radius: 0.06em;
    border-bottom-right-radius: 0.06em;
    background: lightgray;
    transform-origin: calc(50%) calc(100% - 0.06em + 0.5px);
    z-index: -1;
}

.ArrowConstraint.d1 {
    top: -0.45em !important;
    transform: rotate(-45deg);
}

.ArrowConstraint.d2 {
    transform: rotate(0deg);
}

.ArrowConstraint.d3 {
    top: -0.45em !important;
    transform: rotate(45deg);
}

.ArrowConstraint.d4 {
    transform: rotate(-90deg);
}

.ArrowConstraint.d6 {
    transform: rotate(90deg);
}

.ArrowConstraint.d7 {
    top: -0.45em !important;
    transform: rotate(-135deg);
}

.ArrowConstraint.d8 {
    transform: rotate(180deg);
}

.ArrowConstraint.d9 {
    top: -0.45em !important;
    transform: rotate(135deg);
}

.ArrowConstraint.t1 {
    transform: rotate(90deg);
}

.ArrowConstraint.t2 {
    transform: rotate(135deg);
}

.ArrowConstraint.t3 {
    transform: rotate(180deg);
}

.ArrowConstraint.t4 {
    transform: rotate(45deg);
}

.ArrowConstraint.t6 {
    transform: rotate(-135deg);
}

.ArrowConstraint.t7 {
    transform: rotate(0deg);
}

.ArrowConstraint.t8 {
    transform: rotate(-45deg);
}

.ArrowConstraint.t9 {
    transform: rotate(-90deg);
}