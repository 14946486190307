.UIPropertyBox {
    display: flex;
    flex-direction: column;
}

.UIPropertyBox .category {
    color: whitesmoke;
    background: slategray;
    border-radius: 2px;
    padding: 2px 4px 2px 4px;
    margin: 1px 0px 1px 0px;
    user-select: none;
}