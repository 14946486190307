.UIMenuItem button {
    background: none !important;
    border: none !important;
    margin: 0 !important;
    padding: 0 !important;
    color: inherit;
    font: inherit;
    text-decoration: none;
    cursor: pointer;
}

.UIMenuItem button:hover {
    color: white;
    text-decoration: underline;
}

.UIMenuItem button:visited {
    color: whitesmoke;
}

.UIMenuItem button:focus {
    outline: dotted lightgray 1px;
    outline-offset: 2px;
}