.GridCornerMark {
    font-size: 0.4em;
    margin: 0.5em 0.7em 0.5em 0.7em;
    z-index: 10;
}

.GridCornerMark .m1 {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.GridCornerMark .m5 {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.GridCornerMark .m2 {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}

.GridCornerMark .m7 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.GridCornerMark .m9 {
    display: flex;
    justify-content: center;
    align-items: center;
}

.GridCornerMark .m8 {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.GridCornerMark .m3 {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

.GridCornerMark .m6 {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.GridCornerMark .m4 {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}