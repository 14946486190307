.UISearchResult {
    width: var(--result-width);
}

.UISearchResult a {
    color: black;
}

.UISearchResult .thumbnail img {
    display: block;
    width: var(--result-width);
    height: var(--result-width);
}

.UISearchResult .thumbnail .progress {
    position: relative;
    top: -3px;
    border-top: solid darkorange 4px;
}

.UISearchResult .title {
    font-size: small;
}

.UISearchResult .author {
    font-size: x-small;
}

.UISearchResult .offline {
    float: right;
    width: 1em;
    height: 0.8em;
}

.UISearchResult .private {
    float: right;
    width: 1em;
    height: 0.8em;
}

.UISearchResult .updated {
    float: right;
    font-size: x-small;
}