.GridCell {
    display: table-cell;
    position: relative;
    box-sizing: border-box;
    aspect-ratio: 1;
}

.GridCell.square {
    border: 1px solid black;
    cursor: pointer;
}

.GridCell.selected {
    text-shadow:
         0px -1px 0 white,
         0px  1px 0 white,
        -1px  0px 0 white,
         1px  0px 0 white;
}

.GridCell .content {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    pointer-events: none;
}
