.KillerCageConstraint {
    font-size: 0.4em;
}

.KillerCageConstraint .v {
    position: relative;
    top: -2px;
    left: -2px;
    background: white;
    width: fit-content;
    height: fit-content;
    line-height: 0.9em;
}

.fixed .KillerCageConstraint .v {
    background: var(--color-fixed);
}

.violation .KillerCageConstraint .v {
    background: var(--color-violation);
}

.KillerCageConstraint.t {
    margin-top: 3px;
    border-top: 1px dashed black;
}

.KillerCageConstraint.r {
    margin-right: 3px;
    border-right: 1px dashed black;
}

.KillerCageConstraint.b {
    margin-bottom: 3px;
    border-bottom: 1px dashed black;
}

.KillerCageConstraint.l {
    margin-left: 3px;
    border-left: 1px dashed black;
}