.UIUserMenu .UIMenu {
    position: absolute;
    top: 0;
    left: auto;
    right: 0;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    border-right: none;
    border-left: 2px solid whitesmoke;
}

.UIUserMenu .UIMenu {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.UIUserMenu .UIMenu.collapsed {
    width: auto;
}

@media print {
    .UIUserMenu {
        display: none;
    }
}