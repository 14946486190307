.UIInputDigits {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 0.5rem;
}

@media print {
    .UIInputDigits {
        display: none;
    }
}

.UIInputDigitsCol {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 0.5rem;
}

.UIInputDigits button {
    border: solid black 1px;
    padding: 3px;
    width: 3rem;
    height: 3rem;
    cursor: pointer;
}

.UIInputDigits button:hover {
    background-color: white;
}

.UIInputDigits button:hover:active {
    box-shadow: 0 0 5px -1px rgba(0,0,0,0.6);
}

.UIInputDigits .digit {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
}

.UIInputDigits.digit .digit {
    font-size: 1.6rem;
    font-weight: bold;
}

.UIInputDigits.centerMark .digit {
    font-size: 1rem;
    font-weight: normal;
}

.UIInputDigits.cornerMark .digit {
    font-size: 1rem;
    font-weight: normal;
}

.UIInputDigits.cornerMark .digit.m1 {
    justify-content: flex-start;
    align-items: flex-start;
}

.UIInputDigits.cornerMark .digit.m2 {
    justify-content: center;
    align-items: flex-start;
}

.UIInputDigits.cornerMark .digit.m3 {
    justify-content: flex-end;
    align-items: flex-start;
}

.UIInputDigits.cornerMark .digit.m4 {
    justify-content: flex-start;
    align-items: center;
}

.UIInputDigits.cornerMark .digit.m5 {
    justify-content: center;
    align-items: center;
}

.UIInputDigits.cornerMark .digit.m6 {
    justify-content: flex-end;
    align-items: center;
}

.UIInputDigits.cornerMark .digit.m7 {
    justify-content: flex-start;
    align-items: flex-end;
}

.UIInputDigits.cornerMark .digit.m8 {
    justify-content: center;
    align-items: flex-end;
}

.UIInputDigits.cornerMark .digit.m9 {
    justify-content: flex-end;
    align-items: flex-end;
}

.UIInputDigits.colorMark .digit {
    font-size: 1em;
    font-weight: normal;
}

.UIInputDigits.colorMark .digit.m1 {
    background-color: var(--color-1);
}

.UIInputDigits.colorMark .digit.m2 {
    background-color: var(--color-2);
}

.UIInputDigits.colorMark .digit.m3 {
    background-color: var(--color-3);
}

.UIInputDigits.colorMark .digit.m4 {
    background-color: var(--color-4);
}

.UIInputDigits.colorMark .digit.m5 {
    background-color: var(--color-5);
}

.UIInputDigits.colorMark .digit.m6 {
    background-color: var(--color-6);
}

.UIInputDigits.colorMark .digit.m7 {
    background-color: var(--color-7);
}

.UIInputDigits.colorMark .digit.m8 {
    background-color: var(--color-8);
}

.UIInputDigits.colorMark .digit.m9 {
    background-color: var(--color-9);
}
