.SumConstraint {
    font-size: 0.4em;
    text-align: center;
}

.SumConstraint .dot {
    position: relative;
    width: 1em;
    height: 1em;
    border: 1px solid white;
    border-radius: 1em;
    background: white;
    z-index: 1;
}

.SumConstraint .l {
    top: calc(50% - 0.5em - 1px);
    left: calc(-0.5em - 1px);
}

.SumConstraint .t {
    top: calc(-0.5em - 1px);
    left: calc(50% - 0.5em - 1px);
}