.UIRoot {
    box-sizing: border-box;
}

.UIRoot {
    --header-height: 2rem;

    --body-margin: 2rem;
    --body-padding: calc(var(--body-margin) / 2);
}

@media print {
    .UIRoot {
        --header-height: 0px;
    }
}

.UIRoot {
    --left-width: min(calc(100vh - var(--header-height) - var(--body-margin) - 2px), calc(100vw - var(--body-margin) - 2px));
}

@media (orientation: landscape) {
    .UIRoot {
        --right-width: calc(100vw - var(--left-width) - var(--header-height) - var(--body-padding) - 4px);
    }
}

@media (orientation: portrait) {
    .UIRoot {
        --right-width: var(--left-width);
    }
}
