
.UIEditProperties {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: var(--left-width);
    min-width: 18rem;
}

.UIEditProperties > .UIPropertyTree {
    height: calc(50vh - 2rem);
    overflow: scroll;
    padding: 1px;
}

.UIEditProperties > .UIPropertyBox {
    height: calc(50vh - 3rem - 14px);
    overflow: scroll;
}