.UIUserButton {
    margin-top: 5px;
    color: whitesmoke;
}

.UIUserButton button {
    background: whitesmoke;
    border: 1px solid whitesmoke;
    border-radius: 2px;
    cursor: pointer;
}

.UIUserButton button:hover {
    background: white;
}

.UIUserButton button:focus {
    outline: dotted lightgray 1px;
    outline-offset: 2px;
}