.UIMenu .UIMenuHamburger {
    width: 2rem;
    height: 1rem;
    padding-top: 2px;
    padding-bottom: 2px;
    margin-right: 8px;
    margin-top: 6px;
    margin-bottom: 1rem;
    border-radius: 2px;
    cursor: pointer;
}

.UIMenu .UIMenuHamburger:focus {
    outline: dotted lightgray 1px;
    outline-offset: 2px;
}

.UIMenu .UIMenuHamburger .icon {
    width: calc(2rem - 9px);
    height: calc(1rem - 4px);
    position: relative;
    display: block;
    margin-left: 4px;
}

.UIMenu .UIMenuHamburger .line {
    display: block;
    width: 100%;
    height: 3px;
    border-radius: 1px;
    background: whitesmoke;
    position: absolute;
    left: 0;
}

.UIMenu .UIMenuHamburger:hover .line {
    background: white;
}

.UIMenu .UIMenuHamburger .line-1 {
    top: 0;
}

.UIMenu .UIMenuHamburger .line-2 {
    top: 50%;
}

.UIMenu .UIMenuHamburger .line-3 {
    top: 100%;
}

.UIMenu.expanded .UIMenuHamburger .line-1 {
    transform: translateY(calc(0.5rem - 2px)) translateX(0) rotate(45deg);
}

.UIMenu.expanded .UIMenuHamburger .line-2 {
    opacity: 0;
}

.UIMenu.expanded .UIMenuHamburger .line-3 {
    transform: translateY(calc(-0.5rem + 2px)) translateX(0) rotate(-45deg);
}