
.BetweenConstraint.bulb {
    width: 0.9em;
    height: 0.9em;
    top: calc(50% - 0.45em - 0.12em - 1px) !important;
    left: calc(50% - 0.45em - 0.12em) !important;
    border: 0.12em solid lightgray;
    border-radius: 1em;
    background: whitesmoke;
}

.BetweenConstraint.line {
    width: 0.12em;
    bottom: calc(50% - 0.06em) !important;
    left: calc(50% - 0.06em) !important;
    border-bottom-left-radius: 0.06em;
    border-bottom-right-radius: 0.06em;
    background: lightgray;
    transform-origin: calc(50%) calc(100% - 0.06em + 0.5px);
    z-index: -1;
}

.BetweenConstraint.d1 {
    top: -0.45em !important;
    transform: rotate(-45deg);
}

.BetweenConstraint.d2 {
    transform: rotate(0deg);
}

.BetweenConstraint.d3 {
    top: -0.45em !important;
    transform: rotate(45deg);
}

.BetweenConstraint.d4 {
    transform: rotate(-90deg);
}

.BetweenConstraint.d6 {
    transform: rotate(90deg);
}

.BetweenConstraint.d7 {
    top: -0.45em !important;
    transform: rotate(-135deg);
}

.BetweenConstraint.d8 {
    transform: rotate(180deg);
}

.BetweenConstraint.d9 {
    top: -0.45em !important;
    transform: rotate(135deg);
}
