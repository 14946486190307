.ThermoConstraint {
    background: lightgray;
}

.ThermoConstraint.bulb {
    width: 1em;
    height: 1em;
    top: calc(50% - 0.5em) !important;
    left: calc(50% - 0.5em) !important;
    border-radius: 0.5em;
}

.ThermoConstraint.line {
    width: 0.2em;
    bottom: calc(50% - 0.1em) !important;
    left: calc(50% - 0.1em) !important;
    border-bottom-left-radius: 0.1em;
    border-bottom-right-radius: 0.1em;
    transform-origin: calc(50%) calc(100% - 0.1em);
    z-index: -1;
}

.ThermoConstraint.d1 {
    top: -0.45em !important;
    transform: rotate(-45deg);
}

.ThermoConstraint.d2 {
    transform: rotate(0deg);
}

.ThermoConstraint.d3 {
    top: -0.45em !important;
    transform: rotate(45deg);
}

.ThermoConstraint.d4 {
    transform: rotate(-90deg);
}

.ThermoConstraint.d6 {
    transform: rotate(90deg);
}

.ThermoConstraint.d7 {
    top: -0.45em !important;
    transform: rotate(-135deg);
}

.ThermoConstraint.d8 {
    transform: rotate(180deg);
}

.ThermoConstraint.d9 {
    top: -0.45em !important;
    transform: rotate(135deg);
}