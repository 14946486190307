.UIHeader {
    height: 2rem;
    line-height: 2rem;
    padding-left: calc(3rem + 2px + 0.5rem);
    color: white;
    background: slategray;
}

@media print {
    .UIHeader {
        display: none;
    }
}

.UIHeader a {
    color: whitesmoke;
    text-decoration: none;
    border-radius: 2px;
}

.UIHeader a:hover {
    color: white;
    text-decoration: underline;
}

.UIHeader a:visited {
    color: whitesmoke;
}

.UIHeader a:focus {
    outline: dotted lightgray 1px;
    outline-offset: 2px;
}
