.GridCenterMark {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.5em;
    overflow: hidden;
    z-index: 10;
}

.GridCenterMark.d7 {
    font-size: 0.4em;
}

.GridCenterMark.d8 {
    font-size: 0.35em;
}

.GridCenterMark.d9 {
    font-size: 0.3em;
}