
.InequalityConstraint.line {
    width: 0.4em;
    height: 0.4em;
    border-top: 2px solid black;
    border-left: 2px solid black;
    top: calc(50% - 0.2em - 1px) !important;
    left: calc(50% - 0.2em - 1px) !important;
    transform-origin: 50% 50%;
    z-index: 4;
}

.InequalityConstraint.d2 {
    transform: translate(0px, -0.8em) scaleX(0.8) rotate(45deg);
}

.InequalityConstraint.d4 {
    transform: translate(-0.8em, 0px) scaleY(0.8) rotate(-45deg);
}

.InequalityConstraint.d6 {
    transform: translate(0.8em, 0px) scaleY(0.8) rotate(135deg);
}

.InequalityConstraint.d8 {
    transform: translate(0px, 0.8em) scaleX(0.8) rotate(-135deg);
}

.InequalityConstraint .c {
    width: 100%;
    height: 100%;
}

.InequalityConstraint .sp {
    opacity: 0;
}

.GridCell.violation .InequalityConstraint .c {
    background-color: var(--color-violation);
}