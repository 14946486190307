.UIInputConstraints {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 13.5rem;
    gap: 0.5rem;
    overflow: scroll;
}

@media print {
    .UIInputConstraints {
        display: none;
    }
}

.UIInputConstraints .category {
    width: 13.5rem;
    color: whitesmoke;
    background: slategray;
    padding: 2px 4px 2px 4px;
    border-radius: 2px;
    user-select: none;
}

.UIInputConstraints button {
    border: solid black 1px;
    width: 6.5rem;
    height: 2.5rem;
    text-align: left;
}

.UIInputConstraints button:hover {
    background-color: white;
}

.UIInputConstraints button:hover:active {
    box-shadow: 0 0 5px -1px rgba(0,0,0,0.6);
}