
.UIImport {
    margin: 1rem 2rem 1rem 2rem;
    max-width: 50rem;
}

.UIImport .form {
    width: 100%;
}

.UIImport textarea {
    width: calc(100% - 10px);
    padding: 2px 4px 2px 4px;
    border: 1px solid slategray;
    border-radius: 2px;
    resize: vertical;
}

.UIImport .buttons {
    width: 100%;
    font-size: 0.9rem;
}

.UIImport button {
    height: 1.6rem;
}

.UIImport #Status {
    margin-left: 1rem;
    margin-right: 1rem;
}

.UIImport #Play, .UIImport #Edit {
    float: right;
    margin-left: 0.5rem;
}