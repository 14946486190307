
.UISearch {
    padding-left: 1rem;
    padding-right: 1rem;

    --result-width: min(14rem, calc(50vw - 2.5rem));
    --result-gap: 1rem;
}

.UISearch .links {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border: 1px solid slategray;
    border-radius: 2px;
    padding: 0.5rem 1rem 0.5rem 1rem;
    max-width: calc((4 * var(--result-width)) + (1 * var(--result-gap)));
    font-size: small;
}

.UISearch .results {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--result-gap);
    max-width: calc((4 * var(--result-width)) + (3 * var(--result-gap)));
}

.UISearch .navigation {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 0.5rem;
    max-width: calc((4 * var(--result-width)) + (3 * var(--result-gap)));
    margin-top: 1rem;
}