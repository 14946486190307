.UIPropertyNode {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    user-select: none;
}

.UIPropertyNode.indented {
    margin-left: 1em;
}

.UIPropertyNode .node {
    position: relative;
}

.UIPropertyNode .node {
    padding: 1px;
    border-radius: 2px;
    outline-offset: 1px;
    cursor: pointer;
}

.UIPropertyNode label {
    cursor: pointer;
}

.UIPropertyNode .node:focus {
    outline: 1px dotted black;
}

.UIPropertyNode .toggle {
    position: absolute;
    left: -1.2em;
    width: 1em;
    height: 1em;
    cursor: pointer;
}

.UIPropertyNode.selected > .node {
    background: var(--color-selection-trans);
}

.UIPropertyNode.collapsed > .node > .toggle {
    transform: rotate(0deg);
    transition: transform 0.1s;
}

.UIPropertyNode.expanded > .node > .toggle {
    transform: rotate(-90deg);
    transition: transform 0.1s;
}

.UIPropertyNode.collapsed > .children {
    display: none;
}