.UIEdit {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
}

.UIEditInput {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 1rem;
    min-width: 13.5rem;
    max-height: calc(100vh - var(--header-height) - 2rem);
}

.UIEditGrid {
    height: 0%;
    outline-offset: 2px;
}