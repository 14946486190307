
.UISearchForm .search {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 1rem 0rem;
    gap: 1rem;
}

.UISearchForm .text {
    width: calc(var(--result-width) - 2rem);
    height: 1.6rem;
    padding: 0.2em 1em;
}

.UISearchForm button {
    margin-right: 0.5rem;
    height: 2.2rem;
    width: 2.4rem;
    vertical-align: middle;
}

.UISearchForm button svg {
    width: 100%;
    height: 100%;
}